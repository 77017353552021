import { render, staticRenderFns } from "./magazine-page.vue?vue&type=template&id=e48ec3c6&"
import script from "./magazine-page.vue?vue&type=script&lang=js&"
export * from "./magazine-page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MagazineContent: require('/home/node/app/app/components/magazine-content.vue').default,TidalPagination: require('/home/node/app/node_modules/@tidal/styles-navigation/src/components/tidal-pagination.vue').default,DefaultLayout: require('/home/node/app/app/components/default-layout.vue').default})
