import { render, staticRenderFns } from "./magazine-post.vue?vue&type=template&id=9ff70b50&"
import script from "./magazine-post.vue?vue&type=script&lang=js&"
export * from "./magazine-post.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TidalLink: require('/home/node/app/node_modules/@tidal/styles-link/src/components/tidal-link.vue').default,MagazineSearch: require('/home/node/app/app/components/magazine-search.vue').default,TidalArticleCards: require('/home/node/app/node_modules/@tidal/styles-content/src/components/tidal-article-cards.vue').default,TidalPagination: require('/home/node/app/node_modules/@tidal/styles-navigation/src/components/tidal-pagination.vue').default,DefaultLayout: require('/home/node/app/app/components/default-layout.vue').default})
