
export default {
    props: {
        initialSearch: {
            type: String,
            default: '',
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        opacity: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: this.initialSearch,
        };
    },
    methods: {
        submitSearch() {
            // Make sure we get the correct value after debounce
            setTimeout(() => {
                if (this.value.length > 1) {
                    this.$router.push(this.localePath({ name: 'search', query: { q: this.value } }));
                }
            }, 250);
        },
    },
};
