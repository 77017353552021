import { render, staticRenderFns } from "./magazine-search.vue?vue&type=template&id=1b831b40&scoped=true&"
import script from "./magazine-search.vue?vue&type=script&lang=js&"
export * from "./magazine-search.vue?vue&type=script&lang=js&"
import style0 from "./magazine-search.vue?vue&type=style&index=0&id=1b831b40&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b831b40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TidalSearch: require('/home/node/app/node_modules/@tidal/styles-form/src/components/tidal-search.vue').default})
