
export default {
    name: 'Index',
    async asyncData({ $api, app, params }) {
        const { slug } = params;
        /** @type {MagazineFrontend.Article[]} */
        let content = [];
        const page = await $api.magazine.getPageBySlug({ params: { slug: slug || 'page_on_front' } });
        if (!page || !page.id) {
            if (slug) {
                return app.errorNotFound();
            }

            // Fallback for front page if empty
            const articleData = await $api.magazine.getArticles();
            content = articleData.items;
        }

        const menu = await $api.magazine.getMenu();
        const url = app.localePath({ name: 'index', params: { slug } });
        const activeItem = menu.items.find(item => item.href === url);
        const activePage = activeItem ? activeItem.name : null;

        return {
            activePage,
            content,
            magazineNav: menu.items,
            page,
        };
    },
    data() {
        return {
            /** @type {string | null} */
            activePage: null,
            /** @type {MagazineFrontend.Article[]} */
            content: [],
            /** @type {TIDAL.MenuItem[]} */
            magazineNav: [],
            page: /** @type {MagazineFrontend.Page} */({}),
        };
    },
    /** @return {import('vue-meta').MetaInfo} */
    head() {
        const head = this.getDefaultTags();
        if (this.$route.params.slug) {
            head.title = this.page.title;
        }
        if (this.page.description) {
            head.meta?.push({ name: 'description', content: this.page.description, hid: 'desc' });
        }
        head.meta?.push(
            { name: 'og:image', content: 'https://tidal-cms.s3.amazonaws.com/assets/829a10a0-be65-11e9-acf8-c91edbf4e28d/OG-generic.jpg', hid: 'og_img' },
            { name: 'og:image:width', content: String(1200), hid: 'og_img_w' },
            { name: 'og:image:height', content: String(630), hid: 'og_img_h' },
            { name: 'twitter:card', content: 'summary_large_image', hid: 't_card' },
        );
        head.meta?.push({ property: 'og:type', content: 'website', hid: 'og_type' });
        return head;
    },
};
