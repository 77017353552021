
export default {
    props: {
        /** @type {import('vue').PropOptions<MagazineFrontend.Page>} */
        page: {
            type: Object,
            required: true,
        },
        /** @type {import('vue').PropOptions<TIDAL.MenuItem[]>} */
        magazineNav: {
            type: Array,
            required: true,
        },
        activePage: {
            type: String,
            default: null,
        },
        /** @type {import('vue').PropOptions<MagazineFrontend.Pagination | null>} */
        pagination: {
            type: Object,
            default: null,
        },
    },
    computed: {
        /** @return {boolean} */
        darkBg() {
            return this.page.darkBg;
        },
        /** @return {string | null} */
        componentBg() {
            if (this.darkBg) {
                return 'theme-light-dark';
            }
            return null;
        },
        /** @return {MagazineApi.PageBlock | undefined} */
        firstBlock() {
            return this.page.blocks && this.page.blocks[0];
        },
        /** @return {string | undefined} */
        firstBlockName() {
            return this.firstBlock && this.firstBlock.blockName;
        },
        /** @return {boolean} */
        isTakeover() {
            // @ts-ignore
            return this.firstBlockName === 'tidal-blocks/header' && !!this.firstBlock?.attrs.image;
        },
        /** @return {boolean} */
        removePadding() {
            return this.firstBlockName === 'tidal-blocks/header';
        },
        /** @return {boolean} */
        hasTopcard() {
            return this.firstBlockName === 'tidal-blocks/top-card-pick' || this.firstBlockName === 'tidal-blocks/top-card-latest';
        },
    },
};
