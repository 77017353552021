
export default {
    props: {
        /** @type {import('vue').PropOptions<MagazineFrontend.Article[]>} */
        content: {
            type: Array,
            required: true,
        },
        /** @type {import('vue').PropOptions<MagazineApi.PostData[]>} */
        related: {
            type: Array,
            default() {
                return [];
            },
        },
        /** @type {import('vue').PropOptions<TIDAL.MenuItem[]>} */
        magazineNav: {
            type: Array,
            required: true,
        },
        activePage: {
            type: String,
            default: null,
        },
        /** @type {import('vue').PropOptions<MagazineFrontend.Pagination | null>} */
        pagination: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        isSearch: {
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: null,
        },
    },

};
